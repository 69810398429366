import React from 'react'
import Layout from '../components/Layout'

import './about.scss'

const AboutPage = () => {
  return (
    <Layout
      title="Keymaker | About"
      pageClassName="page-about"
    >
      <section className="page-about__hero">
        <div className="page-about__hero__container">
          <div className="page-about__hero__container--left">
            <h1 className="page-title">About Keymaker</h1>
            <p>The World’s First Search Engine for the Metaverse</p>
          </div>
        </div>
      </section>
      <section className="page-about__mission">
        <div className="page-about__mission__container">
          <div className="page-about__mission__container__content">
            <h1 className="title">Our Mission</h1>
            <p>Keymaker is committed to safeguarding an open and connected Metaverse through our search engine and teleportation protocol. Voice is the future interface of the Metaverse, and Keymaker empowers you to search using voice and go wherever you want.</p>
            <p>True freedom of movement is crucial to the healthy and sustainable development of the Metaverse, Web3 and the next-generation Internet. Keymaker unlocks walled gardens so you can go where you want with complete freedom.</p>
          </div>
          <div className="page-about__mission__container__content">
            <h1>Our Values</h1>
            <p className="tag">Open. Connected. Accessible.</p>
            <p>This is our guiding ethos when it comes to realizing the sustainable development of the Metaverse.</p>
          </div>
      </div>
      </section>
      <section className="page-about__users">
        <div className="page-about__users__container">
          <div className="content users">
          <h2 className="content-heading">For Users</h2>
            <p>We empower <span className="font-weight-700">Users</span> to search, navigate and travel seamlessly and with complete freedom across virtual worlds.</p>
          </div>
          <div className="content creators">
            <h2 className="content-heading">For Creators</h2>
            <p>We allow <span className="font-weight-700">Virtual World Creators and Facilitators</span> to collaborate, share traffic and offer complementary experiences.</p>
          </div>
          <div className="content developers">
          <h2 className="content-heading">For Developers</h2>
            <p>To ensure the <span className="font-weight-700">Metaverse Ecosystem</span> as a whole can succeed, we offer interoperability among virtual worlds built on open, widely-adopted standards.</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage